<template lang="html">
  <div class="editor">
    <div ref="toolbar" class="toolbar" :class="{'sticky ':isSticky}">
    </div>
    <div ref="editor" class="text">
    </div>
  </div>
</template>

<script>
  import E from 'wangeditor'
  import contentApi from '@/api/info/content'
  import config from '@/api/config'
  import replaceEditorImgURL from '@/utils/replaceEditorImgURL'

  export default {
    name: 'editoritem',
    data() {
      return {
        editor: null,
        info_: null,
        isSticky: false
      }
    },
    model: {
      prop: 'value',
      event: 'change'
    },
    props: {
      content: {
        type: String,
        default: ''
      },
      stickyHeight: {
        type: Number,
        default: 0
      },
      isClear: {
        type: Boolean,
        default: false
      }
    },
    watch: {
      isClear(val) {
        // 触发清除文本域内容
        if (val) {
          this.editor.txt.clear()
          this.info_ = null
        }
      },
      content: function(value) {
        // value = replaceEditorImgURL.replaceDuplicatePicture(value);
        if (value !== this.editor.txt.html()) {
          this.editor.txt.html(this.content)
        }
      }
      //value为编辑框输入的内容，这里我监听了一下值，当父组件调用得时候，如果给value赋值了，子组件将会显示父组件赋给的值
    },
    mounted() {
      this.seteditor()
      this.editor.txt.html(this.content)
      // 监听滚动条，设置菜单栏吸顶
      window.addEventListener('scroll',this.scrollChange,true)
    },
    unmounted() {
      window.removeEventListener('scroll',this.scrollChange,true)
    },
    methods: {
      // 监听滚动条，设置菜单栏吸顶
      scrollChange(e){
        console.log(e.srcElement.scrollTop)
        console.log(this.stickyHeight)
        if(e.srcElement.scrollTop>this.stickyHeight){
          this.isSticky=true;
        }else{
          this.isSticky=false;
        }
      },
      getContent(){
        alert(this.info_)
        console.log(this.info_)
      },
      seteditor() {
        this.editor = new E(this.$refs.toolbar, this.$refs.editor)

        // 菜单栏提示为下标
        this.editor.config.menuTooltipPosition = 'down'
        // 配置行高
        this.editor.config.lineHeights = ['0.5','0.75', '1', '1.25', '1.5', '2', '2.5', '3']
        // 比菜单栏小
        this.editor.config.zIndex = 10
        this.editor.customConfig = this.editor.customConfig ? this.editor.customConfig : this.editor.config
        // 配置全屏功能按钮是否展示
        this.editor.customConfig.showFullScreen = true;
        //上传图片
        this.editor.customConfig.uploadImgShowBase64 = false // base 64 存储图片
        this.editor.customConfig.uploadImgServer = config.server+'/api/content/uploadImg'// 配置服务器端地址
        this.editor.customConfig.uploadFileName = 'files' // 后端接受上传文件的参数名
        this.editor.customConfig.uploadImgMaxSize = 5 * 1024 * 1024 // 将图片大小限制为 5m
        this.editor.customConfig.uploadImgMaxLength = 5 // 限制一次最多上传 3 张图片
        this.editor.customConfig.uploadImgTimeout = 3 * 60 * 1000 // 设置超时时间
        this.editor.config.showLinkImg = false
        
        // 配置菜单
        this.editor.customConfig.menus = [
          'head', // 标题
          'bold', // 粗体
          'fontSize', // 字号
          'fontName', // 字体
          'italic', // 斜体
          'underline', // 下划线
          'strikeThrough', // 删除线
          'indent',// 缩进
          'lineHeight',// 行高
          'foreColor', // 文字颜色
          'backColor', // 背景颜色
          'link', // 插入链接
          'list', // 列表
          'justify', // 对齐方式
          'quote', // 引用
          'emoticon', // 表情
          'image', // 插入图片
          'table', // 表格
          'video', // 插入视频
          'code', // 插入代码
          'undo', // 撤销
          'redo', // 重复
          'fullScreen' // 全屏
        ]
        //上传图片
        this.editor.customConfig.customUploadImg = (files, insert) => {

          files.forEach((element) => { //循环对粘贴图片执行上传回显功能
            var formData = new FormData();
            formData.append("file", element);

            contentApi.uploadFile(formData).then(res => {
              console.log(res.data)
              insert(config.server + res.data.data[0]);
            })
          });
        }



        //上传视频
        this.editor.config.uploadVideoServer = config.server+'/api/content/uploadFile'
        this.editor.config.uploadVideoMaxSize = 1 * 1024 * 1024 * 1024 // 1024m
        this.editor.config.uploadVideoAccept = ['mp4'] //限制类型
        this.editor.config.uploadVideoName = 'file'  //自定义 fileName

        this.editor.config.uploadVideoHooks = {
            // 上传视频之前
            before: function(xhr) {
                console.log(xhr)
            },
            // 视频上传并返回了结果，视频插入已成功
            success: function(xhr) {
                console.log('success', xhr)
            },
            // 视频上传并返回了结果，但视频插入时出错了
            fail: function(xhr, editor, resData) {
                console.log('fail', resData)
            },
            // 上传视频出错，一般为 http 请求的错误
            error: function(xhr, editor, resData) {
                console.log('error', xhr, resData)
            },
            // 上传视频超时
            timeout: function(xhr) {
                console.log('timeout')
            },
            // 视频上传并返回了结果，想要自己把视频插入到编辑器中
            // 例如服务器端返回的不是 { errno: 0, data: { url : '.....'} } 这种格式，可使用 customInsert
            customInsert: function(insertVideoFn, result) {
                // result 即服务端返回的接口
                // insertVideoFn 可把视频插入到编辑器，传入视频 src ，执行函数即可
                insertVideoFn(config.server +result.data[0])
            }
        }

        this.editor.customConfig.onchange = (html) => {
          this.info_ = html // 绑定当前逐渐地值
          this.$emit('change', this.info_) // 将内容同步到父组件中
        }

        // 自定义处理粘贴的文本内容
        this.editor.config.pasteTextHandle = (pasteStr) => {
          // 获取粘贴内容中的img标签个数
          var imgReg = /<img.*?(?:>|\/>)/gi;      //正则匹配图片（g表示匹配所有结果i表示区分大小写）
          var arr = pasteStr.match(imgReg);
          // 禁止单图的图文粘贴，为了解决“右键-复制图片”，粘贴时，显示两张图片的问题
          if(arr!=null && arr.length==1&&arr[0].trim()==pasteStr.trim()) {
            return '';
          }
          // 对粘贴的文本进行处理，然后返回处理后的结果
          return pasteStr
        }

        // 创建富文本编辑器
        this.editor.create()
      }
    }
  }
</script>

<style lang="css">
  .editor {
    width: 100%;
    margin: 0 auto;
    position: relative;
    z-index: 0;
  }
  .toolbar {
    border: 1px solid #ccc;
  }
  /*吸顶样式*/
  .sticky {
    position: fixed;
    top: 0px;
    z-index: 101;
    border: 2px solid #409eff;
  }
  .text {
    border: 1px solid #ccc;
    min-height: 500px;
    /*margin-top: 40px;*/
  }
  .w-e-text-container{
    /*height: 550px !important;*/
  }
</style>